import {
  H1,
  H2,
  H3,
  H4,
} from './index.css';

export default {
  H1,
  H2,
  H3,
  H4,
};
